import React, { useEffect, useRef } from "react";
import data from "./data.json";
import { useParams } from "react-router-dom";
import he from "he";

function Project() {
  // project data
  const { pjId } = useParams();
  const project = data.find((item) => item.id === pjId);
  const title = project ? project.title : "Project Title";
  const client = project ? project.client : "Project Client";
  const cover = project ? project.thumbnail : "/thumb.png";
  const description = project ? project.description : "hey there";
  const menu = project ? project.menu : "diamond";
  const tags = project ? project.tags : "diamond";

  useEffect(() => {
    // Reusable function to observe an element
    const observeElement = (observedElementId, targetElementId, classToAdd) => {
      const observedElement = document.getElementById(observedElementId);
      const targetElement = document.getElementById(targetElementId);

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // When the observed element comes into view, add the class to the target element
          targetElement.classList.add(classToAdd);
        } else {
          // When the observed element goes out of view, remove the class from the target element
          targetElement.classList.remove(classToAdd);
        }
      });

      // Start observing the observed element
      observer.observe(observedElement);

      // Clean up the observer when the component unmounts
      return () => {
        observer.unobserve(observedElement);
      };
    };

    // Observe StepOne to add "active" class to MenuOne
    observeElement("StepOne", "MenuOne", "active");

    // Observe StepTwo to add "active" class to MenuTwo
    observeElement("StepTwo", "MenuTwo", "active");

    // Add more similar calls for other elements as needed
    observeElement("StepThree", "MenuThree", "active");
    observeElement("StepFour", "MenuFour", "active");
  }, []);

  return (
    <div className="page">
      {menu === "diamond" ? (
        <div className="diamond">
          <div id="MenuOne">D</div>
          <div id="MenuTwo">C</div>
          <div id="MenuThree">D</div>
          <div id="MenuFour">C</div>
        </div>
      ) : (
        <div className="designThinking">
          <div className="phase" id="MenuOne">
            E
          </div>
          <div className="phase" id="MenuTwo">
            D
          </div>
          <div className="phase" id="MenuThree">
            I
          </div>
          <div id="MenuFour">
            <div className="phase">P</div>
            <div className="phase">T</div>
          </div>
        </div>
      )}

      <div className="projectWrapper">
        <img className="cover" src={cover} alt={title} />
        <div className="pjHead">
          <h1>{title}</h1>
          <p className="large">{client}</p>
        </div>
        <div className="pjBody">
          {(() => {
            try {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: he.decode(description),
                  }}
                ></div>
              );
            } catch (error) {
              return <p>Error rendering HTML: {error.message}</p>;
            }
          })()}

          <div className="pjTags">
            {tags.map((tag, index) => (
              <a
                key={index}
                className="tag large"
                href={`/tag/${tag.replace(/ /g, "-")}`}
              >
                <i className="fa-solid fa-hashtag fa-xs"></i>
                {tag}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
