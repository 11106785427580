import React from "react";

import data from "./data.json";

function NotFound() {
  return (
    <div className="page">
      <h1>Sorry, not found.</h1>
      <p className="large">Check out one of my projects instead?</p>

      <div className="itemList">
        <div className="listHead">
          <h2>Projects</h2>
        </div>

        <div className="items">
          {data.map((item) => (
            <div key={item.id} className="item">
              <a href={item.url}>
                <img src={item.thumbnail} alt={item.title} />
              </a>
              <div className="item-info">
                <a href={item.url}>
                  <h3>{item.title}</h3>
                  <p className="large">{item.client}</p>
                </a>
                <div className="item-tags">
                  {item.tags.map((tag, index) => (
                    <a
                      key={index}
                      className="tag"
                      href={`/tag/${tag.replace(/ /g, "-")}`}
                    >
                      <i class="fa-solid fa-hashtag fa-xs"></i>
                      {tag}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotFound;
