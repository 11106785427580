import React, { Component } from "react";
import data from "./data.json";
import { useParams } from "react-router-dom";

class ProjectEdit extends Component {
  constructor() {
    super();
    this.state = {
      jsonData: data,
      idToUpdate: "sevilla-fc-homepage", // The ID of the item to update
      newTitle: "", // This will store the new title
    };
  }

  componentDidMount() {
    const { jsonData, idToUpdate } = this.state;
    const selectedItem = jsonData.find((item) => item.id === idToUpdate);
    if (selectedItem) {
      this.setState({ newTitle: selectedItem.title });
    }
  }

  handleTitleChange = (e) => {
    this.setState({ newTitle: e.target.value });
  };

  updateTitle = () => {
    const { jsonData, idToUpdate, newTitle } = this.state;
    const updatedData = jsonData.map((item) => {
      if (item.id === idToUpdate) {
        return { ...item, title: newTitle };
      }
      return item;
    });
    this.setState({ jsonData: updatedData });
  };

  render() {
    const { newTitle } = this.state;

    return (
      <div>
        <h1>Update JSON Data</h1>
        <p>Current Title:</p>
        <input type="text" value={newTitle} onChange={this.handleTitleChange} />
        <button onClick={this.updateTitle}>Update Title</button>
      </div>
    );
  }
}

export default ProjectEdit;
