import React from "react";
import data from "./data.json";
import { useParams } from "react-router-dom";

function TagExample(props) {
  const { tagId } = useParams();
  const specificTag = tagId.replace("-", " ");
  const filteredData = data.filter((item) => item.tags.includes(specificTag));

  return (
    <div className="page">
      <div className="itemList">
        <div className="listHead">
          <h2>Projects with</h2>
          <div className="tag large">
            <i className="fa-solid fa-hashtag fa-xs"></i>
            {tagId}

            <a href="/tags/">
              <i className="fa-solid fa-close fa-sm"></i>
            </a>
          </div>
        </div>

        <div className="items">
          {filteredData.map((item) => (
            <div key={item.id} className="item">
              <a href={`/project/${item.id}`}>
                <img src={item.thumbnail} alt={item.title} />
              </a>
              <div className="item-info">
                <a href={`/project/${item.id}`}>
                  <h3>{item.title}</h3>
                  <p className="large">{item.client}</p>
                </a>
                <div className="item-tags">
                  {item.tags.map((tag, index) => (
                    <a key={index} className="tag" href={`/tag/${tag}`}>
                      <i class="fa-solid fa-hashtag fa-xs"></i>
                      {tag}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TagExample;
