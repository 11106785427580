import React, { useEffect, useState } from "react";

import data from "./data.json";

function Home() {
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    const targetDiv = document.getElementById("targetDiv");

    const handleScroll = () => {
      const scrollPosition = targetDiv.scrollTop;

      if (scrollPosition >= 50) {
        setAddClass(true);
      }
    };

    targetDiv.addEventListener("scroll", handleScroll);

    return () => {
      targetDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="page">
      <div
        id="divToAddClass"
        className={`homeCover ${addClass ? "fullHeight" : ""}`}
      >
        <h1>Hi. I'm Carula Garat.</h1>
        <p className="large">And this is my portfolio.</p>
      </div>

      <div id="targetDiv" className="itemList">
        <div className="listHead">
          <h2>Projects</h2>
        </div>

        <div className="items">
          {data.map((item) => (
            <div key={item.id} className="item">
              <a href={`/project/${item.id}`}>
                <img src={item.thumbnail} alt={item.title} />
              </a>
              <div className="item-info">
                <a href={`/project/${item.id}`}>
                  <h3>{item.title}</h3>
                  <p className="large">{item.client}</p>
                </a>
                <div className="item-tags">
                  {item.tags.map((tag, index) => (
                    <a
                      key={index}
                      className="tag"
                      href={`/tag/${tag.replace(/ /g, "-")}`}
                    >
                      <i className="fa-solid fa-hashtag fa-xs"></i>
                      {tag}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
