import React from "react";

function Contact() {
  return (
    <div className="page">
      <h1>Contact me.</h1>
      <a
        className="contact"
        href="https://linkedin.com/in/carula"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i class="icon-in"></i>
        <b>
          <span>linkedin.com/in/</span>
          carula
        </b>
      </a>
      <a
        className="contact"
        href="tel:0034600044026"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i class="icon-phone"></i>
        <b>
          <span>+34 </span>
          600 044 026
        </b>
      </a>
      <a
        className="contact"
        href="mailto:carulagarat@gmail.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        <i class="icon-envelope"></i>
        <b>
          carulagarat
          <span>@gmail.com</span>
        </b>
      </a>
    </div>
  );
}

export default Contact;
