import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import Contact from "./Contact";
import Home from "./Home";
import Tags from "./Tags";
import TagExample from "./TagExample";
import Project from "./Project";
import NotFound from "./NotFound";
import ProjectEdit from "./ProjectEdit";

function App() {
  const trick = <div className="pjTrick" />;

  return (
    <Router>
      <Routes>
        <Route path="/project/:pjId" exact element={trick} />
      </Routes>

      <div className="mainFrame">
        <nav>
          <div>
            <NavLink activeClassName="active" to="/">
              <i className="icon-home"></i>
            </NavLink>
            <NavLink activeClassName="active" to="/tags">
              <i className="icon-hashtag"></i>
            </NavLink>
            <NavLink activeClassName="active" to="/contact">
              <i className="icon-user"></i>
            </NavLink>
          </div>
        </nav>
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/tags" element={<Tags />} />
            <Route path="/tag/:tagId" element={<TagExample />} />
            <Route path="/project/:pjId" element={<Project />} />
            <Route path="/edit/project/:pjId" element={<ProjectEdit />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
