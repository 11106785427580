import React from "react";
import data from "./data.json";

function Tags() {
  const allTags = Array.from(new Set(data.flatMap((item) => item.tags)));

  return (
    <div className="page">
      <h1>All tags.</h1>

      <div className="tags">
        {allTags.map((tag, index) => (
          <a key={index} className="tag large" href={`/tag/${tag}`}>
            <i class="fa-solid fa-hashtag fa-xs"></i>
            {tag}
          </a>
        ))}
      </div>
    </div>
  );
}

export default Tags;
